<template>
<!-- 云健身-中间 -->
    <div class="contain">
		<div class="container darkBack">
			<div class="contentItem lightBack">
				<img class="img" :src="data[0].img" >
				<div>
					<div class="lightGrayFont">{{data[0].title}}</div>
					<div class="whiteFont">
						<countTo :startVal='data[0].preNum' :endVal='data[0].num' :duration='3000'></countTo>分
					</div>
				</div>
			</div>
			<div class="contentItem lightBack">
				<img class="img" :src="data[1].img" >
				<div>
					<div class="lightGrayFont">{{data[1].title}}</div>
					<div class="whiteFont">
						<countTo :startVal='data[1].preNum' :endVal='data[1].num' :duration='3000'></countTo>
					</div>
				</div>
			</div>
			<div class="contentItem lightBack">
				<img class="img" :src="data[2].img" >
				<div>
					<div class="lightGrayFont">{{data[2].title}}</div>
					<div class="whiteFont">
						<countTo :startVal='data[2].preNum' :endVal='data[2].num' :duration='3000'></countTo>分
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {timestamp} from "/src/assets/js/websocket";

	// 引入数字滚动组件
import countTo from 'vue-count-to';
  export default {
    components: { countTo },
		data() {
		    return { 
				data:[
					{ 
						title:'每日平均运动时长' , 
						num:0, 
						img:require('../../../assets/images/now.png'),
						preNum:0 
					},
					{ 
						title:'每周平均运动次数' , 
						num:0, 
						img:require('../../../assets/images/books.png'),
						preNum:0 
					},
					{ 
						title:'总人均时长', 
						num:0, 
						img:require('../../../assets/images/all.png'),
						preNum:0 
					},
				],
		        timer:""
		    }
		},
		methods:{
			getData(){
				let that = this;
				var data = {
					token: window.getToken(),
					app_id: window.appId,
					time: timestamp(),
				}

				let info = JSON.parse(window.localStorage.getItem("screenUserInfo"));
				let url;
				if(info.is_city == 1) url = window.appHost+"/api/v1/screen/Cloud_Gym/vital"
				else url = window.appHost+"/api/v1/screen/Cloud_Gym/vital?area="+info.name

				let result = window.ajaxRequest(url,JSON.stringify(data),'post')
				// console.log(result)
				that.data[0].preNum = that.data[0].num;
				that.data[1].preNum = that.data[1].num;
				that.data[2].preNum = that.data[2].num;
				that.data[0].num = result.data.avgtime;
				that.data[1].num = result.data.weeknum;
				that.data[2].num = result.data.totaltime;
			}
		},
		mounted () {
			let that = this;
			that.getData();
			that.timer = setInterval(() => {
				that.getData();
			}, 300000);
			
		},
		beforeDestroy(){
			clearInterval(this.timer)
		}
	}
</script>

<style scoped>
    .contain{
        position: relative;
    }
	.container{
		width: 12.11rem;
		height: 0.81rem;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.contentItem{
		width: 3.49rem;
		height: 0.81rem;
		display: flex;
		align-items: center;
	}
	.img{
		width: 0.33rem;
		height: 0.34rem;
		margin-right: 0.3rem;
        margin-left:0.36rem;
	}
    .lightGrayFont{
        font-size: 0.14rem;
        color:#CCCCCC;
    }
    .whiteFont{
        font-size: 0.24rem;
        color:#FFFFFF;
    }
</style>